<template>
  <test-report-items :observations="true">
    <test-report-item
      :title="observations.neuroticismo.level"
      :text="observations.neuroticismo.observation"
    />
    <test-report-item
      :title="observations.extraversión.level"
      :text="observations.extraversión.observation"
    />
    <test-report-item
      :title="observations.apertura.level"
      :text="observations.apertura.observation"
    />
    <test-report-item
      :title="observations.amabilidad.level"
      :text="observations.amabilidad.observation"
    />
    <test-report-item
      :title="observations.responsabilidad.level"
      :text="observations.responsabilidad.observation"
    />
  </test-report-items>
</template>

<script>
import TestReportItems from "@/components/TestReportItems.vue";
import TestReportItem from "@/components/TestReportItem.vue";

export default {
  name: "neo-observations",
  props: {
    resultObservations: {},
  },
  components: {
    TestReportItems,
    TestReportItem,
  },
  computed: {
    observations() {
      const observations = {};
      for (const iterator in this.resultObservations) {
        let title = iterator
          .split("-")[0]
          .trim()
          .toLowerCase();

        observations[title] = {
          level: iterator,
          observation: this.resultObservations[iterator],
        };
      }

      return observations;
    },
  },
};
</script>
