<template>
  <content-wrapper>
    <template v-slot:top=""><base-go-back /></template>
    <base-divider text="Información del candidato" borderColor="#000000" />

    <test-report-items>
      <test-report-item title="Nombre" :text="result.candidate.lastname" />
      <test-report-item
        title="Apellido Paterno"
        :text="result.candidate.lastname"
      />
      <test-report-item title="Apellido Materno" text="Chaparro" />
      <test-report-item title="Sexo" :text="result.candidate.sexo" />
      <test-report-item title="Telefono" :text="result.candidate.phonenumber" />
      <test-report-item title="Email" :text="result.candidate.email" />
      <test-report-item
        title="Nivel de estudios"
        :text="result.candidate.levelStudy.name"
      />

      <test-report-item title="Carrera" :text="result.candidate.career" />
    </test-report-items>

    <base-divider text="Información del test realizado" borderColor="#000000" />
    <test-report-items>
      <test-report-item title="Nombre" :text="result.quiz.name" />
      <test-report-item
        title="Duracion maxima"
        :text="result.quiz.maxDuration"
      />
      <test-report-item title="Duracion obtenida" :text="result.duration" />
      <test-report-item title="Puntaje obtenido" :text="result.score" />
    </test-report-items>

    <base-divider text="Observaciones" borderColor="#000000" />
    <component
      :is="observationComponentName"
      :resultObservations="resultObservations"
    />
  </content-wrapper>
</template>

<script>
import TestReportItems from "../../components/TestReportItems.vue";
import TestReportItem from "../../components/TestReportItem.vue";
import DiscObservations from "./testResultObservations/DiscObservations";
import NeoObservations from "./testResultObservations/NeoObservations";
import ICObservations from "./testResultObservations/ICObservations";

export default {
  name: "test-result",
  components: {
    TestReportItems,
    TestReportItem,
    DiscObservations,
    NeoObservations,
    ICObservations,
  },
  data: () => ({
    result: {
      quiz: {
        name: "",
      },
      candidate: {
        name: "",
        lastname: "",
        levelStudy: {
          name: "",
        },
      },
      observations: [],
    },
  }),
  created() {
    this.$store
      .dispatch("Quiz/fetchQuizResultsById", { id: this.$route.params.id })
      .then((res) => {
        this.result = res.data;
      });
  },
  computed: {
    resultObservations() {
      const observations = {};

      for (const el of this.result.observations) {
        observations[el.title] = el.observation;
      }

      return observations;
    },
    observationComponentName() {
      if (this.result.quiz.id === 1) return "ICObservations";
      if (this.result.quiz.id === 2) return "NeoObservations";
      if (this.result.quiz.id === 3) return "DiscObservations";
      return "";
    },
  },
};
</script>

<style>
.test_report {
  width: 100%;
  max-width: 809px;
  height: auto;
  margin: 26px 0;
  padding: 30px 20px;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
</style>
