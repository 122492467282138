<template>
  <test-report-items :observations="true">
    <test-report-item title="Perfil" :text="resultObservations.perfil" />
    <test-report-item title="Emociones" :text="resultObservations.emociones" />
    <test-report-item title="Influye" :text="resultObservations.influye" />
    <test-report-item title="Su Valor" :text="resultObservations.suValor" />
    <test-report-item title="Abusa" :text="resultObservations.abusa" />
    <test-report-item
      title="Bajo presion"
      :text="resultObservations.bajoPresion"
    />
    <test-report-item title="Teme" :text="resultObservations.teme" />
    <test-report-item
      title="Seria Eficaz"
      :text="resultObservations.seriaEficaz"
    />
    <test-report-item
      title="Descripcion del perfil"
      :text="[
        resultObservations.descripcionP1,
        resultObservations.descripcionP2,
      ]"
      :row="true"
    />
  </test-report-items>
</template>

<script>
import TestReportItems from "@/components/TestReportItems.vue";
import TestReportItem from "@/components/TestReportItem.vue";

export default {
  name: "disc-observations",
  props: {
    resultObservations: {},
  },
  components: {
    TestReportItems,
    TestReportItem,
  },
};
</script>
