<template>
  <div></div>
</template>

<script>
export default {
  name: "delete",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: () => "",
    },
  },
  watch: {
    value(newValue) {
      const objectLength = Object.getOwnPropertyNames(newValue).length;
      const isEmpty = objectLength === 0 || objectLength === 1;
      if (!isEmpty) {
        this.showDialog();
      }
    },
  },
  methods: {
    async showDialog() {
      let res = await this.$swal({
        title: `¿Estás seguro de eliminar a esto?`,
        html: `Este cambio no se podra revertir!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "Cancelar",
        showClass: {
          popup: "animate__animated animate__zoomIn animate__faster",
          icon: "fe-pulse-warning",
        },
        hideClass: {
          popup: "animate__animated animate__zoomOut animate__faster",
        },
      });

      if (res.isConfirmed) {
        this.handleDelete();
      } else {
        this.close();
      }
    },
    close() {
      this.$emit("closed");
    },
    handleDelete() {
      this.$emit("confirmed", this.value);
      // try {
      //   const response = {
      //     status: 200,
      //   };

      //   if (response.status === 200) {
      //     this.$swal.fire({
      //       icon: "success",
      //       title: "Eliminado!",
      //       text: "El item ha sido eliminado con exito",
      //       showClass: {
      //         popup: "animate__animated animate__zoomIn animate__faster",
      //       },
      //       hideClass: {
      //         popup: "animate__animated animate__zoomOut animate__faster",
      //       },
      //       onClose: () => {
      //         this.close();
      //       },
      //     });
      //   } else {
      //     this.$swal.fire({
      //       icon: "error",
      //       title: "Error...",
      //       text: "Algo ha ocurrido, intente nuevamente.",
      //       showClass: {
      //         popup: "animate__animated animate__zoomIn animate__faster",
      //         icon: "fe-pulse-error",
      //       },
      //       hideClass: {
      //         popup: "animate__animated animate__zoomOut animate__faster",
      //       },
      //       onClose: () => {
      //         this.close();
      //       },
      //     });
      //   }
      // } catch (error) {
      //   this.$swal.fire({
      //     icon: "error",
      //     title: "Error...",
      //     text: "Algo ha ocurrido, intente nuevamente",
      //     showClass: {
      //       popup: "animate__animated animate__zoomIn animate__faster",
      //       icon: "fe-pulse-error",
      //     },
      //     hideClass: {
      //       popup: "animate__animated animate__zoomOut animate__faster",
      //     },
      //     onClose: () => {
      //       this.close();
      //     },
      //   });
      // }
    },
  },
};
</script>

<style lang="css">
@keyframes pulseWarning {
  from {
    box-shadow: 0 0 0 0px rgba(223, 174, 7, 0.2);
  }
  to {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(223, 174, 7, 0);
  }
}

.fe-pulse-warning {
  animation-name: pulseWarning;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  box-shadow: 0px 0px 1px 1px #0000001a;
}

@keyframes pulseError {
  from {
    box-shadow: 0 0 0 0px rgba(217, 1, 1, 0.2);
  }
  to {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(217, 1, 1, 0);
  }
}

.fe-pulse-error {
  animation-name: pulseError;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  box-shadow: 0px 0px 1px 1px #0000001a;
}
</style>
