<template>
  <div
    class="test_report_items"
    :class="[observations ? 'test_report_observations' : '']"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "test-report-items",
  props: {
    observations: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style>
.test_report_items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: left;
  row-gap: 30px;
  column-gap: 30px;
  padding: 20px 40px;
}

.test_report_observations {
  grid-template-columns: 1fr 1fr;
  justify-items: left;
}

@media screen and (max-width: 650px) {
  .test_report_items {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 450px) {
  .test_report_items {
    grid-template-columns: 1fr;
    row-gap: 15px;
    padding: 20px 20px;
  }

  .test_report_observations {
    grid-template-columns: 1fr;
  }
}
</style>
