<template>
  <test-report-items :observations="true">
    <test-report-item :title="escala.title" :text="escala.text" />
    <test-report-item :title="puntaje.title" :text="puntaje.text" />
    <test-report-item :title="puntajeBruto.title" :text="puntajeBruto.text" />
    <test-report-item
      :title="observacionPuntaje.title"
      :text="observacionPuntaje.text"
    />
  </test-report-items>
</template>

<script>
import TestReportItems from "@/components/TestReportItems.vue";
import TestReportItem from "@/components/TestReportItem.vue";

export default {
  name: "ic-observations",
  props: {
    resultObservations: {},
  },
  components: {
    TestReportItems,
    TestReportItem,
  },
  computed: {
    escala() {
      const key = "Escala utilizada";
      return {
        title: key,
        text: this.resultObservations[key],
      };
    },
    puntajeBruto() {
      const key = "Puntaje bruto";
      return {
        title: key,
        text: this.resultObservations[key],
      };
    },
    puntaje() {
      const key = "Puntaje de escala";
      return {
        title: key,
        text: this.resultObservations[key],
      };
    },
    observacionPuntaje() {
      const key = "Observación de puntaje escala";
      return {
        title: key,
        text: this.resultObservations[key],
      };
    },
  },
};
</script>
