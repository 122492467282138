<template>
  <div id="login">
    <section>
      <img src="../assets/img/clear people naranja (1).png" alt="logo" />
      <h1>Acceso administradores</h1>
      <div>
        Accede a tu cuenta para visualizar los diferentes resultados de los
        tests realizados.
      </div>
      <form @submit.prevent="submitHandler">
        <input
          type="text"
          placeholder="Nombre de usuario"
          v-model="model.username"
        />
        <input
          type="password"
          placeholder="Contraseña"
          v-model="model.password"
        />
        <button type="submit" :disabled="submitLoading">
          <template v-if="!submitLoading">Acceder</template>
          <template v-else
            ><span
              class="login-button--loading"
              role="status"
              aria-hidden="true"
            ></span
          ></template>
        </button>
      </form>
    </section>
  </div>
</template>

<script>
export default {
  name: "login",
  data: () => ({
    model: {
      username: "",
      password: "",
    },
    submitLoading: false,
  }),
  methods: {
    submitHandler() {
      this.submitLoading = true;
      this.$store
        .dispatch("Auth/authenticate", { data: this.model })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.$router.push("/");
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
};
</script>

<style scoped>
#login {
  min-width: 100vw;
  min-height: 100vh;
  background: linear-gradient(
      180deg,
      rgba(229, 150, 27, 0.6) 22.4%,
      rgba(255, 255, 255, 0) 81.77%
    ),
    rgba(229, 147, 24, 0.6);

  display: flex;
  justify-content: center;
  align-items: center;
}

/* Login Form */
#login section {
  width: 439px;
  height: 638px;
  padding: 25px 35px;

  background: #ffffff;
  box-shadow: 0px 5px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

#login section img {
  align-self: center;

  /* image 28 */

  width: 117px;
  height: 121px;
}

#login section h1 {
  width: 100%;
  margin-top: 40px;

  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: justify;

  color: #282c35;
}

#login section div {
  width: 100%;
  margin-top: 15px;

  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;

  color: #aaaaaa;
}

#login section form {
  margin-top: 10px;
}

::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;

  color: #aaaaaa;
  opacity: 1;
}
:-moz-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;

  color: #aaaaaa;
  opacity: 1;
}
::-moz-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;

  color: #aaaaaa;
  opacity: 1;
}
:-ms-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;

  color: #aaaaaa;
  opacity: 1;
}

form input {
  margin-top: 20px;

  width: 100%;
  height: 48.91px;
  padding-left: 10px;

  background: #ffffff;
  box-shadow: 0px 1px 4px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;

  font-size: 18px;
  line-height: 21px;

  color: #aaaaaa;
}

form button {
  width: 100%;
  height: 53.26px;
  margin-top: 15px;

  background: #e75d00;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 5px;

  position: relative;
  color: #fff;
}

/* media queries */
@media screen and (max-width: 500px) {
  /* Login Form */
  #login section {
    width: 100%;
    height: 100vh;
    padding: 25px 35px;

    background: #ffffff;
    box-shadow: unset;
    border-radius: unset;
    display: flex;
    flex-direction: column;
  }
}

.login-button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
</style>
