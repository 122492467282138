<template>
  <div class="test-report-item" :style="{ 'grid-column': row ? '1/-1' : '' }">
    <h1 class="test-report-item__title">{{ title }}</h1>
    <p class="test-report-item__text">{{ texto }}</p>
  </div>
</template>

<script>
export default {
  name: "test-report-item",
  props: {
    title: {
      type: String,
      default: () => "",
    },
    text: {
      type: [String, Number, Array],
      default: () => "",
    },
    row: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    texto() {
      if (this.text instanceof Array) {
        let inline = "";
        for (const key of this.text) {
          inline = key + "\n\n";
        }
        return inline;
      } else {
        return this.text;
      }
    },
  },
};
</script>

<style>
.test-report-item__text {
  position: unset;
  text-align: left;
}

.test-report-item__title {
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  color: #000000;
  text-transform: capitalize;
}

.test-report-item__text {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}
</style>
