<template>
  <content-wrapper :paddingY0="true">
    <base-table
      :headers="headers"
      :data="data"
      :tableTitle="tableTitle"
      :loading="tableLoading"
    >
      <template v-slot:content="tableData">
        <tr v-for="(row, index) in tableData.rows" :key="index">
          <td data-label="Nombre">{{ row.candidate.name }}</td>
          <td data-label="Apellido paterno">
            {{ row.candidate.lastname }}
          </td>
          <td data-label="Email">
            {{ row.candidate.email }}
          </td>
          <td data-label="Telefono">
            {{ row.candidate.phonenumber }}
          </td>
          <td data-label="Nivel de estudios">
            {{ row.candidate.levelStudy.name }}
          </td>
          <td data-label="Puntaje" v-if="row.quiz.id === 1">
            {{ scaleScore(row) }}
          </td>
          <td data-label="Perfil" v-if="row.quiz.id === 3">
            {{ perfil(row) }}
          </td>
          <td data-label="Acciones" class="text-center">
            <base-icon-button
              icon="file-alt"
              bgColor="#28B5B5"
              @click="handleShowResultReport(row)"
            ></base-icon-button>
          </td>
        </tr>
      </template>
    </base-table>
  </content-wrapper>
</template>

<script>
export default {
  name: "tests-results",
  data() {
    return {
      headers: [
        "Nombre",
        "Apellido Paterno",
        "Apellido Materno",
        "Email",
        "Nivel de estudios",
        "Acciones",
      ],
      data: [],
      tableLoading: false,
    };
  },
  created() {
    if (this.$route.params.quizId == 1) {
      this.headers = [
        "Nombre",
        "Apellido Paterno",
        "Apellido Materno",
        "Email",
        "Nivel de estudios",
        "Puntaje",
        "Acciones",
      ];
    }

    if (this.$route.params.quizId == 3) {
      this.headers = [
        "Nombre",
        "Apellido Paterno",
        "Apellido Materno",
        "Email",
        "Nivel de estudios",
        "Perfil",
        "Acciones",
      ];
    }

    this.tableLoading = true;
    this.$store
      .dispatch("Quiz/fetchQuizResultsByQuizId", {
        quizId: this.$route.params.quizId,
      })
      .then((res) => {
        this.data = res.data;
      })
      .finally(() => {
        this.tableLoading = false;
      });
  },
  methods: {
    handleShowResultReport(item) {
      this.$router.push({ name: "test-result", params: { id: item.id } });
    },
    perfil(row) {
      const perfil = row.observations.find((el) => el.title === "perfil");
      return perfil.observation;
    },
    scaleScore(row) {
      const score = row.observations.find(
        (el) => el.title.toLowerCase() === "puntaje de escala"
      );

      return score.observation;
    },
  },
  computed: {
    tableTitle() {
      if (this.data.length == 0) return "Resultados";
      return "Resultados " + this.data[0].quiz.name;
    },
  },
};
</script>
