<template>
  <content-wrapper :paddingY0="true">
    <base-table
      :headers="headers"
      :data="data"
      tableTitle="Gestión de usuarios"
      :showAddBtn="true"
      @add-clicked="handleAddClick"
      :loading="tableLoading"
    >
      <template v-slot:content="tableData">
        <tr v-for="(row, index) in tableData.rows" :key="index">
          <td data-label="Nombre">{{ row.name }}</td>
          <td data-label="Apellido paterno">
            {{ row.lastname }}
          </td>
          <td data-label="Apellido materno">
            {{ row.surname }}
          </td>
          <td data-label="Email">
            {{ row.user.email }}
          </td>
          <td data-label="Empresa">
            {{ row.company.name }}
          </td>
          <td data-label="Acciones" class="text-center">
            <base-icon-button
              icon="trash-alt"
              bgColor="#CE1212"
              @click="handleDelete(row)"
              style="margin-right: 10px;"
            ></base-icon-button>

            <base-icon-button
              icon="pencil-alt"
              bgColor="#28B5B5"
              @click="handleEdit(row)"
            ></base-icon-button>
          </td>
        </tr>
      </template>
    </base-table>
    <delete-item
      v-model="itemToDelete"
      @closed="handleClosedDelete"
      @confirmed="handleConfirmedDelete"
    />
  </content-wrapper>
</template>

<script>
import DeleteItem from "../../components/DeleteItem.vue";

export default {
  name: "users-manage",
  components: {
    DeleteItem,
  },
  data() {
    return {
      showDeleteDialog: false,
      hamburgerStatus: false,
      windowWidth: window.innerWidth,
      headers: [
        "Nombre",
        "Apellido Paterno",
        "Apellido Materno",
        "Email",
        "Empresa",
        "Acciones",
      ],
      data: [],
      itemToDelete: {},
      tableLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    this.tableLoading = true;
    this.$store
      .dispatch("Recruiter/fetchAll")
      .then(({ data }) => {
        this.data = data;
      })
      .finally(() => {
        this.tableLoading = false;
      });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    handleDelete(item) {
      this.itemToDelete = Object.assign({}, item);
      this.showDeleteDialog = true;
    },
    handleConfirmedDelete(item) {
      this.$store
        .dispatch("Recruiter/deleteById", { id: item.id })
        .then(({ status }) => {
          if (status === 200) {
            const element = this.data.find((el) => el.id === item.id);
            const index = this.data.indexOf(element);
            this.data.splice(index, 1);

            this.$swal.fire({
              icon: "success",
              title: "Eliminado!",
              text: "El usuario ha sido eliminado con existo.",
              showClass: {
                popup: "animate__animated animate__zoomIn animate__faster",
              },
              hideClass: {
                popup: "animate__animated animate__zoomOut animate__faster",
              },
            });
          }
        })
        .finally(() => {
          this.handleClosedDelete();
        });
    },
    handleClosedDelete() {
      this.itemToDelete = {};
      this.showDeleteDialog = false;
    },
    handleEdit(item) {
      this.$router.push({ name: "edit-user", params: { userId: item.id } });
    },
    handleAddClick() {
      this.$router.push({ name: "create-user" });
    },
  },
  computed: {
    showMenu() {
      if (this.windowWidth <= 500) {
        if (this.hamburgerStatus) return true;
        return false;
      } else return true;
    },
  },
};
</script>
